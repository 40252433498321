import { useState, forwardRef } from 'react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import styles from './PasswordInput.module.scss';

type Props = any;

export const PasswordInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [show, setShow] = useState(false);

  const { children, className, ...inputProps } = props;
  return (
    <div className={`${styles.passwordInput} ${className}`}>
      <input
        maxLength={100}
        className={`${styles.inputText}`}
        type={show ? 'text' : 'password'}
        ref={ref}
        {...inputProps}
      />
      <span className={styles.eye} onClick={() => setShow(!show)}>
        {show ? <FaEye /> : <FaEyeSlash />}
      </span>
    </div>
  );
});
PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
