import React, { useEffect, useState } from 'react';

import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';

import type { NextPage } from 'next';

import { useForm } from 'react-hook-form';

import { useRecoilValue } from 'recoil';

import PasswordInput from '@components_iqfu/public/password_input/PasswordInput';
import PublicPageContainer from '@components_iqfu/public/public_page_container/PublicPageContainer';

import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';
import userUuidValue from '@recoil_iqfu/atoms/public/user_uuid';

import NotificationBar from '@components_common/notification_bar/NotificationBar';
import { emailRegex } from '@constants_common';
import { ChatValidationText, NotificationText, ValidationText } from '@constants_iqfu';
import { useAuth } from '@contexts_common/authContext';
import styles from '@styles_iqfu/public/login.module.scss';

const metaTitle =
  'イクラ不動産 ログインページ|簡単で素早い査定と高値での不動産売却・買取を実現。おすすめの不動産会社を一覧で比較！|「自分に合った」売却・買取・査定を実現する不動産売却専門サイト';

const metaDescription =
  '「自分に合った、高値での不動産売却・買取」「どこよりも簡単で素早い査定」を実現する不動産売却専門サイト、「イクラ不動産」のログインページです。不動産の売却相場・価格の調査や査定が素早く・簡単にできるのはもちろん、弊社で審査して厳選した、「土地・（中古）マンション・（中古）一戸建て」の売却・買取・査定が得意な、地元のおすすめの加盟不動産会社を比較検討できます。';

const metaOGUrl = `${process.env.NEXT_PUBLIC_CLIENT_BASEPATH}/login`;

const SellerUserLoginPage: NextPage = () => {
  const { sellerLogin } = useAuth();
  const router = useRouter();
  const [showError, setShowError] = useState<boolean>(false);

  const { tokenValidity } = useAuth();

  const [data, setData] = useState({
    email: '',
    password: '',
  });

  type validationInput = {
    email: string;
    password: string;
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<validationInput>({ mode: 'onSubmit', reValidateMode: 'onChange' });

  const [lineAuthUrl, setLineAuthUrl] = useState('');

  const userUuid = useRecoilValue(userUuidValue);
  const isLoggedIn = useRecoilValue(sellerIsLoggedIn);

  const disableSubmit = errors.email || errors.password;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (showError) setShowError(false);
    const target = e.target;
    const name = target.name as 'email' | 'password';
    const value = target.value;

    setData({ ...data, [name]: value });
  };

  const onSubmitHandler = async () => {
    // stateからemailとpasswordを取得する
    const { email, password } = data;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const result = await sellerLogin(formData, userUuid);

    if (result) {
      const redirectUrl = router.query.redirect ? decodeURIComponent(router.query.redirect as string) : undefined;

      //※router.pushにしない
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/chat/talk';
      }
    } else {
      setShowError(true);
    }
  };

  const authChecked = tokenValidity !== 'unvalidated';
  useEffect(() => {
    if (authChecked && isLoggedIn) {
      router.push('/chat/talk');
    }
  }, [authChecked]);

  useEffect(() => {
    //lineログインURLに必要なランダム値
    const lineState = Math.random().toString(36).slice(-8);

    const redirectUrl = router.query.redirect ? decodeURIComponent(router.query.redirect as string) : undefined;

    const currentDomain = window.location.protocol + '//' + window.location.host;
    const redirectUri = encodeURIComponent(
      `${currentDomain}/line-loggedin?referer=selling_consultation_without_company${
        userUuid ? `&sid=${userUuid}` : ''
      }${redirectUrl ? `&redirect=${redirectUrl}` : ''}`,
    );
    const lineAuthUrl = `
  https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_LINE_CHANNEL_ID}&redirect_uri=${redirectUri}&scope=profile%20openid&state=${lineState}&bot_prompt=aggressive`;
    setLineAuthUrl(lineAuthUrl);
    //alert(lineAuthUrl);
  }, []);

  //isLoggedInのrecoilのstateがssr時の初期値とstorageの値で変わりhydration errorになるので、マウント後にisLoggedInの値を確認する
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);

  return (
    <>
      <Head>
        <title key="title">{metaTitle}</title>
        <meta name="description" content={metaDescription} key="description" />
        <meta property="og:title" content={metaTitle} key={'og_title'} />
        <meta property="og:description" content={metaDescription} key={'og_description'} />
        <meta property="og:url" content={metaOGUrl} key={'og_url'} />
        <meta name="twitter:title" content={metaTitle} key={'twitter_title'} />
        <meta name="twitter:description" content={metaDescription} key={'twitter_description'} />
        <link rel="canonical" href={metaOGUrl} />
      </Head>

      <PublicPageContainer>
        {isMounted && authChecked && !isLoggedIn && (
          <div className={styles.login_container}>
            <NotificationBar
              open={showError}
              toggle={(prevState: boolean) => setShowError(!prevState)}
              close={() => setShowError(false)}
              message={NotificationText.loginFailed}
              status={'alert'}
            />

            <div className={styles.login_wrapper}>
              <div className={styles.login_card}>
                <img src={`/images/iqfu/login_logo.svg`} className={styles.service_logo} alt="イクラ不動産ロゴ" />
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className={styles.mail_address_login}>
                    <p className={styles.address_intro}>メールアドレスでログイン</p>
                    <div className={styles.form}>
                      <div className={styles.input_wrap}>
                        <input
                          type="text"
                          placeholder="メールアドレス"
                          data-test="email-input"
                          className={styles.input}
                          {...register('email', {
                            required: true,
                            pattern: { value: emailRegex, message: ValidationText.invalidEmail },
                            onChange: (e) => handleInputChange(e),
                          })}
                          onKeyPress={(e: any) => {
                            if (e.key == 'Enter') onSubmitHandler();
                          }}
                        />
                        {errors.email && (
                          <p className={styles.error_message}>
                            {errors.email.type !== 'required'
                              ? `※${errors.email.message}`
                              : `※${ChatValidationText.required}`}
                          </p>
                        )}
                      </div>
                      <div className={styles.input_wrap}>
                        <PasswordInput
                          placeholder="パスワード"
                          className={'h48'}
                          {...register('password', {
                            required: true,
                            minLength: {
                              value: 8,
                              message: ValidationText.passwordMinLength,
                            },
                            onChange: (e) => handleInputChange(e),
                          })}
                          data-test="password-input"
                          onKeyPress={(e: any) => {
                            if (e.key == 'Enter') onSubmitHandler();
                          }}
                        />
                        {errors.password && (
                          <p className={styles.error_message}>
                            {errors.password.type !== 'required'
                              ? `※${errors.password.message}`
                              : `※${ChatValidationText.required}`}
                          </p>
                        )}
                      </div>
                      <button
                        className={`${styles.login_button} ${disableSubmit ? styles.disabled : ''}`}
                        type="submit"
                        data-test="login-submit"
                      >
                        ログイン
                      </button>
                    </div>
                    <Link
                      href={lineAuthUrl}
                      className={`${styles.line_button} ${disableSubmit ? styles.disabled : ''}`}
                    >
                      <div className={styles.line_button_overlay}></div>
                      <div className={styles.line_icon_wrap}>
                        <img
                          className={styles.line_icon}
                          src="/images/iqfu/public/signup/line_icon.png"
                          alt="Lineアイコン"
                        />
                      </div>
                      <p className={styles.line_button_text}>LINEログイン</p>
                    </Link>
                    <Link href="/forgot-password" passHref className={styles.forget_pass}>
                      パスワードをお忘れの方
                    </Link>
                  </div>
                </form>
              </div>
              <div className={styles.navigate_item}>
                <div className={styles.button_column}>
                  <p className={styles.navigate_item_condition}>イクラ不動産に未登録の場合</p>
                  <Link href="/signup" prefetch={false} className={styles.chat_navigation_button}>
                    会員登録して、
                    <br className="sp_only" />
                    売却相談チャットを始める
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </PublicPageContainer>
    </>
  );
};

export default SellerUserLoginPage;
